<template>
  <Modal
    :mask-closable="false"
    :styles="{top:'50px'}"
    @on-cancel="close"
    class="prototypes-deal-item-modal"
    title="需处理的物料"
    v-model="flag"
    width="550"
  >
    <Form :label-width="100" :model="formd" :rules="rules" @submit.native.prevent inline ref="form">
      <FormItem label="物料名称：">{{formd.Name}}</FormItem>
      <br />
      <FormItem label="内部编号：">{{formd.GoodsNo}}</FormItem>
      <FormItem label="原编号：">{{formd.Code}}</FormItem>
      <FormItem label="物料类型：">{{ $designConst.goods.SubType[formd.Type] }}</FormItem>
      <FormItem label="处理数量：" prop="Qty">
        <Input :disabled="formd.Type == 1" v-model="formd.Qty" />
      </FormItem>
      <FormItem label="电压：">
        <Input v-model="formd.Voltage" />
      </FormItem>
      <FormItem label="内阻：">
        <Input v-model="formd.InternalResistance" />
      </FormItem>
      <FormItem label="外观：">
        <Input v-model="formd.Exterior" />
      </FormItem>
      <FormItem label="气味：">
        <Input v-model="formd.Smell" />
      </FormItem>
      <FormItem class="long" label="其他说明：">
        <Input type="textarea" v-model="formd.Description" />
      </FormItem>
      <FormItem label="物料图片：" style="margin-bottom:0"></FormItem>
      <div style="position:relative;margin:0 20px;">
        <div class="attas">
          <input
            @change="onFiles"
            accept="image/*"
            multiple
            ref="attasInput"
            style="display:none;"
            type="file"
          />
          <div
            @click="()=>{$refs.attasInput.click()}"
            class="bk-add-file"
            style="margin-right: 10px;"
            v-if="$refs.attasInput"
          >
            <Icon color="#808695" size="20" type="md-add"></Icon>
          </div>
          <div :key="`img-${i}`" class="atta" v-for="(img, i) in imageList">
            <img :src="img" @click="viewImg(i)" alt />
            <Icon
              @click="removeImg(i)"
              class="icon-remove"
              color="#ed4014"
              size="20"
              type="md-close-circle"
            />
          </div>
        </div>
      </div>
    </Form>
    <div slot="footer">
      <Button :loading="posting" @click="ok" type="primary">确定</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    OrderId: Number,
    DealId: Number,
  },
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Qty: [
          {
            validator: (rule, value, callback) => {
              /^\d+$/.test(value) ? callback() : callback(new Error(" "));
            },
          },
        ],
      },
      imageList: [],
      imageList_: [],
    };
  },
  methods: {
    open(data) {
      this.formd = data;
      let imgs = [];
      if (this.formd.Img1) imgs.push(this.formd.Img1);
      if (this.formd.Img2) imgs.push(this.formd.Img2);
      if (this.formd.Img3) imgs.push(this.formd.Img3);
      if (this.formd.Img4) imgs.push(this.formd.Img4);
      if (this.formd.Img5) imgs.push(this.formd.Img5);
      this.imageList = imgs;
      this.imageList_ = imgs.concat([]);
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
      this.imageList = [];
      this.imageList_ = [];
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      console.log(this.formd);
      let formdata = new FormData();
      formdata.append("OrderId", this.OrderId);
      formdata.append("DealId", this.DealId);
      for (let key in this.formd) {
        if (key == "OrderId") {
          continue;
        } else if (key == "DealId") {
          continue;
        } else if (
          ["Img1", "Img2", "Img3", "Img4", "Img5"].indexOf(key) != -1
        ) {
          continue;
        } else {
          console.log(key);
          formdata.append(key, this.formd[key] || "");
        }
      }
      console.log(formdata);
      let obj = {};
      for (let a of this.imageList_) {
        if (typeof a == "string") {
          if (!obj.Img1) {
            formdata.append("Img1", a);
            obj.Img1 = true;
          } else if (!obj.Img2) {
            formdata.append("Img2", a);
            obj.Img2 = true;
          } else if (!obj.Img3) {
            formdata.append("Img3", a);
            obj.Img3 = true;
          } else if (!obj.Img4) {
            formdata.append("Img4", a);
            obj.Img4 = true;
          } else if (!obj.Img5) {
            formdata.append("Img5", a);
            obj.Img5 = true;
          }
        } else {
          formdata.append("File", a);
        }
      }
      console.log(formdata);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/SaveDealItem", formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("保存成功");
            this.close();
            this.id > 0 ? this.$emit("on-upd") : this.$emit("on-add");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    onFiles(e) {
      if (e.target.files.length > 0 && this.imageList_.length < 5) {
        for (let i = 0; i < e.target.files.length; i++) {
          if (this.imageList_.length < 5) {
            this.imageList.push(this.$util.file2url(e.target.files[i]));
            this.imageList_.push(e.target.files[i]);
          } else {
            break;
          }
        }
      }
      e.target.value = "";
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
    removeImg(i) {
      this.imageList.splice(i, 1);
      this.imageList_.splice(i, 1);
      this.formd.Img1 = "";
      this.formd.Img2 = "";
      this.formd.Img3 = "";
      this.formd.Img4 = "";
      this.formd.Img5 = "";
      for (let a of this.imageList_) {
        if (typeof a == "string") {
          if (this.formd.Img1 == "") {
            this.formd.Img1 = a;
          } else if (this.formd.Img2 == "") {
            this.formd.Img2 = a;
          } else if (this.formd.Img3 == "") {
            this.formd.Img3 = a;
          } else if (this.formd.Img4 == "") {
            this.formd.Img4 = a;
          } else if (this.formd.Img5 == "") {
            this.formd.Img5 = a;
          }
        }
      }
    },
  },
};
</script>
<style lang="less">
.prototypes-deal-item-modal {
  .ivu-modal-body {
    overflow-y: scroll;
  }
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .attas {
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
}
</style>
