<template>
  <div>
    <Modal
      class="pick-goods"
      v-model="flag"
      :title="title"
      @on-cancel="close"
      :styles="{top:'50px'}"
      width="842"
    >
      <Input
        v-model="searchData1.Name"
        suffix="ios-search"
        placeholder="输入名称"
        style="width: auto; margin-bottom: 10px"
        @on-enter="onSearch"
      />
      <div class="bk-loading-line" v-show="loading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>
      <ul>
        <li class="goods-item" v-for="(item, i) in lst" :key="i">
          <Button class="add" type="primary" size="small" @click="ok(i)"
            >选择</Button
          >
          <div class="img-box" @click="viewImgs(i)">
            <img :src="item.Img1" alt="" />
            <span>{{ item.imgCount }}张</span>
          </div>
          <div class="right">
            <div class="text-xs">
              <span class="text-sub">名称：</span>{{ item.Name }}
            </div>
            <div class="text-xs">
              <span class="text-sub">类型：</span>
              {{ $designConst.goods.Type[item.Type] }} -
              {{ $designConst.goods.SubType[item.SubType] }}
            </div>
            <div class="text-xs">
              <span
                ><span class="text-sub">内部编号：</span
                >{{ item.GoodsNo || "无" }}；</span
              >
              <span
                ><span class="text-sub">原编号：</span
                >{{ item.Code || "无" }}；</span
              >
            </div>
          </div>
        </li>
      </ul>
      <div slot="footer">
        <div class="flex">
          <Page
            :total="all"
            :current="pi"
            :page-size="ps"
            transfer
            show-total
            show-sizer
            show-elevator
            @on-change="(index) => onChangePage(index, 0)"
            @on-page-size-change="(size) => onChangePage(1, size)"
            style="flex: 1; text-align: left; line-height: 32px"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "选择物料",
      type: String,
    },
    multiple: Boolean,
    orderid: [String, Number],
    type: [String, Number],
    status: [String, Number],
  },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      flag: false,
      loading: false,
      pi: 1,
      ps: 10,
      all: 0,
      lst: [],
    };
  },
  methods: {
    open(lst) {
      this.flag = true;
      this.reqLst();
    },
    close() {
      this.flag = false;
      this.picked = [];
      this.obj = {};
    },
    ok(i) {
      let data = this.$util.copy(this.lst[i]);
      this.$emit("on-ok", data);
    },
    onSearch() {
      this.searchData2 = this.$util.copy(this.searchData1);
      this.reqLst();
    },
    reqLst() {
      this.lst = [];
      let params = Object.assign(
        {
          PageIndex: this.pi,
          PageSize: this.ps,
          OrderId: this.orderid,
          UserType: this.type,
          Status: this.status,
        },
        this.searchData2
      );
      this.loading = true;
      this.$axios
        .get("/Api/WmsGoods/QueryPg", { params: params })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            let lst = [];
            for (let i in res.data.DataSet) {
              let a = res.data.DataSet[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              lst.push(a);
            }
            this.lst = lst;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error("网络错误");
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    viewImgs(i) {
      let imgs = [];
      if (this.lst[i].Img1) imgs.push(this.lst[i].Img1);
      if (this.lst[i].Img2) imgs.push(this.lst[i].Img2);
      if (this.lst[i].Img3) imgs.push(this.lst[i].Img3);
      if (this.lst[i].Img4) imgs.push(this.lst[i].Img4);
      if (this.lst[i].Img5) imgs.push(this.lst[i].Img5);
      this.$root.$emit("view-imgs", imgs);
    },
  },
};
</script>
<style lang="less">
.pick-goods {
  .ivu-modal-body {
    padding: 16px 0 16px 16px;
  }
  .bk-loading-line {
    font-size: 16px;
    padding: 10px 0 20px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  .goods-item {
    width: 400px;
    flex-shrink: 0;
    display: flex;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    .img-box {
      display: block;
      width: 58px;
      height: 58px;
      flex-shrink: 0;
      margin-right: 5px;
      position: relative;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      img:hover {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
      }
      .right {
        flex: 1;
        flex-shrink: 0;
        padding: 5px;
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 2px;
        border-bottom-left-radius: 4px;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        font-size: 12px;
      }
    }
    .add {
      position: absolute;
      top: 0px;
      right: 0px;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>