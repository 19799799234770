<template>
  <div>
    <Drawer
      :mask-closable="false"
      :transfer="false"
      :width="id > 0 ? 801 : 400"
      @on-close="close"
      class="bk-sub-drawer prototypes-deal-drawer"
      v-model="flag"
    >
      <div class="ivu-drawer-header-inner" slot="header">
        <span v-if="id==0">新增</span>
        <span v-else>
          <span>详情</span>
          <Divider type="vertical" />
          <span class="text-sub text-sm">{{$designConst.prototypesDeal.Status[formd.Status]}}</span>
        </span>
      </div>
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="flex" style="height: 100%">
        <div class="left-items" v-if="id > 0">
          <div class="flex" style="align-items: center; margin-bottom: 5px">
            <div class="items-title">物料清单：</div>
            <!-- <Button
              @click="toPickGoods"
              icon="md-add"
              size="small"
              type="primary"
              v-if="ABLE && formd.Status==1"
            ></Button> -->
            <Button
              @click="toPickGoods"
              icon="md-add"
              size="small"
              type="primary"
              v-if="ABLE"
            ></Button>
          </div>
          <ul>
            <li :key="i" class="goods-item" v-for="(item, i) in items">
              <Icon
                @click="toDelItem(i)"
                class="close"
                color="#ed4014"
                size="16"
                type="md-close"
                v-if="formd.Status <= 1"
              />
              <Icon
                @click="toUpdItem(i)"
                class="edit"
                color="#2d8cf0"
                size="16"
                type="md-create"
                v-if="formd.Status <= 1"
              />
              <div @click="viewImgs(i)" class="img-box">
                <img :src="item.Img1" alt />
                <span>{{ item.imgCount }}张</span>
              </div>
              <div class="right">
                <div class="text-xs">
                  <span class="text-sub">名称：</span>
                  {{ item.Name }}
                </div>
                <div class="text-xs">
                  <span class="text-sub">类型：</span>
                  {{ $designConst.goods.SubType[item.Type] }}
                </div>
                <div class="text-xs">
                  <span>
                    <span class="text-sub">内部编号：</span>
                    {{ item.GoodsNo || "无" }}；
                  </span>
                  <span>
                    <span class="text-sub">原编号：</span>
                    {{ item.Code || "无" }}；
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="right-main">
          <div class="bk-drawer-body">
            <Form
              :label-width="120"
              :model="formd"
              :rules="rules"
              @submit.native.prevent
              ref="form"
            >
              <FormItem label="委托单：" prop="OrderId" required>
                <Input
                  :value="formd.OrderCode"
                  @on-clear="unPickOrder"
                  clearable
                  readonly
                  v-if="ABLE && id == 0"
                >
                  <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
                </Input>
                <div v-else>{{formd.OrderCode}}</div>
              </FormItem>
              <FormItem label="分类：" prop="Type" required>
                <Select
                  @on-change="onType"
                  v-if="ABLE && this.formd.Status <= 1"
                  v-model="formd.Type"
                >
                  <Option
                    :key="i"
                    :value="a.key"
                    v-for="(a, i) in $designConst.prototypesDeal.TypeLst"
                  >{{ a.name }}</Option>
                </Select>
                <div v-else>{{$designConst.prototypesDeal.Type[formd.Type]}}</div>
              </FormItem>
              <FormItem label="样品回收公司：" prop="PecyclingCompId">
                <Input
                  :disabled="formd.Type == 2 || formd.Type == 3"
                  :value="formd.RecyclingCompName"
                  @on-clear="unPickTrader"
                  clearable
                  readonly
                  v-if="ABLE"
                >
                  <Button
                    :disabled="formd.Type == 2 || formd.Type == 3"
                    @click="toPickTrader"
                    icon="ios-more"
                    slot="append"
                  ></Button>
                </Input>
                <div v-else>{{formd.RecyclingCompName}}</div>
              </FormItem>
              <FormItem label="收件地址：" prop="PostAddress">
                <Input
                  :disabled="formd.Type == 1 || formd.Type == 3"
                  type="textarea"
                  v-if="ABLE"
                  v-model="formd.PostAddress"
                />
                <div v-else>{{formd.PostAddress}}</div>
              </FormItem>
              <FormItem label="物流信息：" prop="LogisticsInfo">
                <Input
                  :disabled="formd.Type == 3"
                  type="textarea"
                  v-if="ABLE"
                  v-model="formd.LogisticsInfo"
                />
                <div v-else>{{formd.LogisticsInfo}}</div>
              </FormItem>
              <FormItem label="备注：">
                <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
                <div v-else>{{formd.Remark}}</div>
              </FormItem>
            </Form>
            <div class="attas" v-if="id > 0">
              <Spin class="bk-loading" fix v-if="imageLoading != 0">
                <div>
                  <Icon type="ios-loading"></Icon>
                  <div v-if="imageLoading == -1">删除中...</div>
                  <div v-if="imageLoading == 1">加载中...</div>
                  <div v-if="imageLoading == 2">提交中...</div>
                </div>
              </Spin>
              <input
                @change="onFiles"
                accept="image/*"
                multiple
                ref="attasInput"
                style="display: none"
                type="file"
              />
              <div
                @click="
                  () => {
                    $refs.attasInput.click();
                  }
                "
                class="bk-add-file"
                style="margin-right: 10px"
                v-if="ABLE && $refs.attasInput"
                v-show="imageList.length < 5"
              >
                <Icon color="#808695" size="20" type="md-add"></Icon>
              </div>
              <div :key="`img-${i}`" class="atta" v-for="(img, i) in imageList">
                <img :src="img" @click="viewImg(i)" alt />
                <Icon
                  @click="toRemoveImg(i)"
                  class="icon-remove"
                  color="#ed4014"
                  size="20"
                  type="md-close-circle"
                  v-if="ABLE"
                />
              </div>
            </div>
          </div>
          <div class="bk-drawer-footer" v-if="ABLE">
            <Button :loading="posting" @click="ok" type="primary">确定</Button>
          </div>
        </div>
      </div>
    </Drawer>

    <pick-order @on-ok="onPickOrder" ref="PickOrder" status="4" />
    <pick-trader @on-ok="onPickTrader" ref="PickTrader" type="4" />
    <pick-goods :orderid="formd.OrderId" @on-ok="onPickGoods" ref="PickGoods" />
    <item-edit
      :DealId="formd.Id"
      :OrderId="formd.OrderId"
      @on-add="onAddItem"
      @on-upd="onUpdItem"
      ref="itemEdit"
    />
  </div>
</template>
<script>
// 状态（0：草稿，1：待确认，2：待签收，3：已结束）
import PickOrder from "../../components/PickOrder.vue";
import PickTrader from "../../components/PickTrader.vue";
import PickGoods from "../../components/PickGoods.vue";
import itemEdit from "./item.vue";
export default {
  components: { PickOrder, PickTrader, PickGoods, itemEdit },
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        Type: [{ required: true, type: "number", message: " " }],
        PecyclingCompId: [
          {
            validator: (rule, value, callback) => {
              if (this.Type == 1) {
                this.formd.PecyclingCompId
                  ? callback()
                  : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        PostAddress: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.Type == 2) {
                this.formd.PostAddress ? callback() : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        LogisticsInfo: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.Type == 3) {
                callback();
              } else {
                this.formd.LogisticsInfo
                  ? callback()
                  : callback(new Error(" "));
              }
            },
          },
        ],
      },
      items: [],
      imageLoading: 0, // -1：删除；1：加载；2新增；
      imageList: [],
      imageList_: [],
    };
  },
  methods: {
    open(id) {
      if (id) {
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.WmsDealAdd;
        this.formd.Status = 1;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
      this.items = [];
      this.imageList = [];
      this.imageList_ = [];
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsPrototypesDeal/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.ABLE = this.$able.map.WmsDealSave && res.data.Status != 3;
            this.formd = res.data.Main;
            let lst = [];
            for (let i in res.data.Items) {
              let a = res.data.Items[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              lst.push(a);
            }
            this.items = lst;
            this.onType();
            this.$nextTick(() => {
              this.reqAttas();
            });
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if (this.id > 0 && this.items.length == 0) {
          //   this.$Message.warning("请选择物料清单");
          //   return;
          // }
          this.postData();
        }
      });
    },
    postData() {
      let api,
        params = this.$util.copy(this.formd);
      if (this.id > 0) {
        api = "/Api/BtmsPrototypesDeal/Update";
      } else {
        api = "/Api/BtmsPrototypesDeal/Add";
      }
      this.posting = true;
      this.$axios
        .post(api, params)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("保存成功");
              this.$emit("on-upd");
              this.req();
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
              this.id = res.data;
              this.req();
              this.$refs.PickGoods.open();
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      this.items = [];
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.$set(this.formd, "Type", lst[0].PrototypeProcessWay);
        if (this.flag2) {
          this.lst = [];
          this.pi = 1;
          this.reqGoods();
        }
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
        this.$set(this.formd, "Type", "");
        if (this.flag2) {
          this.lst = [];
          this.pi = 1;
        }
      }
      this.onType();
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.$set(this.formd, "Type", "");
    },
    // 类型
    onType() {
      if (this.formd.Type == 1) {
        // 由受委托方代为处理
        this.PostAddress = "";
      } else if (this.formd.Type == 2) {
        // 由受委托方代为寄回
        this.formd.RecyclingCompId = "";
        this.$set(this.formd, "RecyclingCompName", "");
      } else if (this.formd.Type == 3) {
        // 委托方自行取回
        this.formd.RecyclingCompId = "";
        this.$set(this.formd, "RecyclingCompName", "");
        this.PostAddress = "";
        this.LogisticsInfo = "";
      }
    },
    // 选择公司
    toPickTrader() {
      if (this.formd.RecyclingCompId) {
        this.$refs.PickTrader.open([
          {
            Id: this.formd.RecyclingCompId,
            Name: this.formd.RecyclingCompName,
          },
        ]);
      } else {
        this.$refs.PickTrader.open();
      }
    },
    onPickTrader(lst) {
      if (lst[0]) {
        this.formd.RecyclingCompId = lst[0].Id;
        this.$set(this.formd, "RecyclingCompName", lst[0].Name);
      } else {
        this.formd.RecyclingCompId = "";
        this.$set(this.formd, "RecyclingCompName", "");
      }
    },
    unPickTrader() {
      this.formd.RecyclingCompId = "";
      this.$set(this.formd, "RecyclingCompName", "");
    },
    toPickGoods() {
      this.$refs.PickGoods.open();
    },
    onPickGoods(goods) {
      let d = {
        Type: goods.SubType,
        Name: goods.Name,
        Code: goods.Code,
        GoodsId: goods.Id,
        GoodsNo: goods.GoodsNo,
        Qty: 1,
        Voltage: goods.Voltage,
        InternalResistance: goods.InternalResistance,
        Exterior: goods.Exterior,
        Smell: goods.Smell,
        Img1: goods.Img1,
        Img2: goods.Img2,
        Img3: goods.Img3,
        Img4: goods.Img4,
        Img5: goods.Img5,
        Description: goods.Description,
      };
      this.$refs.itemEdit.open(d);
    },
    onAddItem() {
      this.req();
      this.$refs.PickGoods.close();
    },
    toUpdItem(i) {
      let d = this.$util.copy(this.items[i]);
      this.$refs.itemEdit.open(d);
    },
    onUpdItem() {
      this.req();
    },
    toDelItem(i) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDelItem(this.items[i].Id);
        },
      });
    },
    doDelItem(id) {
      this.$axios
        .post("/Api/BtmsPrototypesDealItems/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.req();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    // 附件
    reqAttas() {
      this.imageLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 12,
            OrderId: this.formd.OrderId,
            DataId: this.formd.Id,
          },
        })
        .then((res) => {
          this.imageLoading = 0;
          if (res.result) {
            this.imageList = res.data.map((v) => v.Url);
            this.imageList_ = res.data;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.imageLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onFiles(e) {
      if (e.target.files.length > 0 && this.imageList_.length < 5) {
        for (let i = 0; i < e.target.files.length; i++) {
          if (this.imageList_.length < 5) {
            this.imageList.push(this.$util.file2url(e.target.files[i]));
            this.imageList_.push(e.target.files[i]);
          } else {
            break;
          }
        }
        this.saveAttas();
      }
      e.target.value = "";
    },
    saveAttas() {
      let formdata = new FormData();
      formdata.append("OrderId", this.formd.OrderId);
      formdata.append("DataId", this.formd.Id);
      formdata.append("Type", 12);
      let hasFile = false;
      for (let a of this.imageList_) {
        if (a.Id == undefined) {
          formdata.append("File", a);
          hasFile = true;
        }
      }
      if (hasFile) {
        this.imageLoading = 2;
      } else {
        return;
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          this.imageLoading = 0;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttas();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.imageLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
    toRemoveImg(i) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.removeImg(i);
        },
      });
    },
    removeImg(i) {
      if (this.imageList_[i].Id) {
        this.imageLoading = -1;
        this.$axios
          .post("/Api/DataAttachment/Delete", { Ids: this.imageList_[i].Id })
          .then((res) => {
            if (res.result) {
              this.reqAttas();
            } else {
              this.imageLoading = 0;
              this.$Message.error(res.errormsg);
            }
          })
          .catch((err) => {
            this.imageLoading = 0;
            this.$Message.error(err.errormsg);
          });
      } else {
        this.imageList.splice(i, 1);
        this.imageList_.splice(i, 1);
      }
    },
    viewImgs(i) {
      console.log(this.items[i]);
      let imgs = [];
      if (this.items[i].Img1) {
        imgs.push(this.items[i].Img1);
      }
      if (this.items[i].Img2) {
        imgs.push(this.items[i].Img2);
      }
      if (this.items[i].Img3) {
        imgs.push(this.items[i].Img3);
      }
      if (this.items[i].Img4) {
        imgs.push(this.items[i].Img4);
      }
      if (this.items[i].Img5) {
        imgs.push(this.items[i].Img5);
      }
      this.$root.$emit("view-imgs", imgs);
    },
  },
};
</script>
<style lang="less">
.prototypes-deal-drawer {
  .ivu-drawer-body {
    padding: 0;
  }
  .right-main {
    width: 400px;
    height: 100%;
    position: relative;
    .bk-drawer-body {
      padding: 10px 15px 10px 5px;
    }
    .attas {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 5px 0 5px 35px;
    }
    .atta {
      list-style: none;
      width: 58px;
      height: 58px;
      float: left;
      margin-right: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }
      img:hover {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
      }
      .icon-remove {
        position: absolute;
        top: -5px;
        right: -5px;
        cursor: pointer;
      }
    }
    .atta:last-child {
      margin-right: 0;
    }
  }

  .left-items {
    width: 400px;
    height: 100%;
    border-right: 1px solid #dcdee2;
    padding: 10px 15px 10px 15px;
  }
  .left-items .items-title {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    color: #515a6e;
  }

  .ivu-form-item {
    margin-bottom: 10px;
  }

  .goods-item {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    .img-box {
      display: block;
      width: 58px;
      height: 58px;
      flex-shrink: 0;
      margin-right: 5px;
      position: relative;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      img:hover {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
      }
      .right {
        flex: 1;
        flex-shrink: 0;
        padding: 5px;
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 2px;
        border-bottom-left-radius: 4px;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        font-size: 12px;
      }
    }
    .close {
      padding: 2px;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
    }
    .edit {
      padding: 2px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 25px;
    }
  }
}
</style>